.card:hover,
.card:focus,
.card:active {
  color: #006633;
  border-color: #006633;
}

.FormGroup {
  line-height: 20%;
}

.card-print {
  margin-bottom: -15px;
  font-size: 17px;
}
.card-update {
  margin-bottom: 5px;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    display: block;
    page-break-before: always;
    page-break-after: always;
  }
}

@page {
  // size: legal;
  // size: 326mm 215mm;
  size: 215mm 326mm;
  margin: 0px;
}
