// Variable overrides
$sidebar-dark-bg: white;
$theme-colors: (
  'primary': green,
  'indo': blue,
  'eng': red,
);
$font-family-base: Roboto;
$sidebar-dark-nav-link-color: black;

$sidebar-dark-nav-link-icon-color: black;
$sidebar-dark-nav-link-active-bg: #2eb85c;
